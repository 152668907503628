import React, { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';

import {
  getComma,
  getSupplyPaymentPrice,
  getVatPaymentPrice,
  removeComma,
} from '@/util/format';
import Typography from '../../common/Typography';
import SelectBox from '../SupplyDetail/SelectBox';
import FlexWrap from '../../common/FlexWrap';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import { ProductTypeList } from '@/constants/supply';
import { kioskRefundModalState } from '@/store/modules/validate';
import { addPriceData, updateRefundInfo } from '@/store/modules/refund';
import { DIRECT_PRICE } from '@/constants/common';

function PorteRefundModal({ onClose, setIsEditedRefund }: any) {
  const [isActive, setIsActive] = useState(false);
  const [isOver600Modal, setIsOver600Modal] = useState(false);
  const [selectValue, setSelectValue] = useState('');
  const {
    register,
    setFocus,
    getValues,
    setValue,

    formState: { errors },
  } = useFormContext();
  const dispatch = useAppDispatch();
  const { temporaryStorage, isScanned } = useAppSelector(
    (state) => state.refund,
  );

  useEffect(() => {
    setFocus('totalPrice');
    setValue('totalPrice', '');
    setValue('paymentCard', '');
    setValue('paymentCash', '');
  }, []);

  const onChangeTotalPrice = (e: any) => {
    const value =
      e.target.value.charAt(0) === '0'
        ? e.target.value.substring(1).replace(/[^0-9]/g, '')
        : e.target.value.replace(/[^0-9]/g, '');

    setValue('totalPrice', getComma(value));
    setValue('paymentCard', getComma(value));

    setIsActive(value !== '');

    if (
      isOver600Modal &&
      getValues('totalPrice').replace(/(,)/g, '') > DIRECT_PRICE
    ) {
      return;
    }

    if (getValues('totalPrice').replace(/(,)/g, '') > DIRECT_PRICE) {
      setIsOver600Modal(true);
    } else {
      setIsOver600Modal(false);
    }
  };

  const onChangeCashPrice = (e: any) => {
    // 금액이 0으로 시작하는거 방지
    const value = e.target.value.replace(/^0+/, '').replace(/[^0-9]/g, '');

    let totalValue = parseFloat(getValues('totalPrice').replace(/,/g, '') || 0);
    const cashValue = Math.min(value, totalValue);
    const cardValue = totalValue - cashValue;

    setValue('paymentCash', getComma(Math.min(totalValue, +value)));
    setValue('paymentCard', getComma(cardValue));
  };

  // 임시저장 값 불러오기
  useEffect(() => {
    if (temporaryStorage.supplyDate) {
      setValue('paymentCash', temporaryStorage.paymentCash);
      setValue('paymentCard', temporaryStorage.paymentCard);
      setValue('totalPrice', temporaryStorage.totalPrice);
    }
  }, [temporaryStorage]);

  // 모달 닫앗을때 입력값 첫번째 공급내역에 입력값 할당
  const onClickClose = () => {
    dispatch(updateRefundInfo({ isGroupRefund: false }));

    const currTotalPrice = getValues('totalPrice');
    let currProductType = 15;

    changeAlertMessage();

    ProductTypeList.forEach((item) => {
      if (selectValue === item.value) {
        currProductType = item.key;
      }
    });

    const priceList = splitAmount(removeComma(currTotalPrice));

    if (priceList.length > 3) {
      const addedPriceList = priceList.slice(3);
      const currentSupply = getValues('supply');

      const updatedSupply = [
        ...currentSupply,
        ...addedPriceList.map(() => ({
          productType: '',
          price: '',
          supplyPrice: '',
          vatPrice: '',
          isCheck: false,
        })),
      ];

      setValue('supply', updatedSupply);

      addedPriceList.forEach(() => {
        dispatch(
          addPriceData({
            price: '',
            supplyPrice: '',
            vatPrice: '',
          }),
        );
      });
    }

    priceList.forEach((price, index) => {
      setValue(`supply.${index}.price`, getComma(price));
      setValue(
        `supply.${index}.supplyPrice`,
        getComma(getSupplyPaymentPrice(getComma(price).replace(/(,)/g, ''))),
      );
      setValue(
        `supply.${index}.vatPrice`,
        getComma(
          getVatPaymentPrice(
            getComma(price).replace(/(,)/g, ''),
            getSupplyPaymentPrice(
              getComma(price).replace(/(,)/g, ''),
            ).toString(),
          ),
        ),
      );
      setValue(`supply.${index}.productType`, currProductType);
    });

    dispatch(kioskRefundModalState(false));
  };

  const changeAlertMessage = () => {
    const supplyArray = getValues('supply');
    const initialTotalPrice = supplyArray.reduce((sum: number, item: any) => {
      return sum + Number(item.price);
    }, 0);
    setIsEditedRefund(initialTotalPrice !== 0);
  };

  const splitAmount = (amount: number) => {
    if (amount <= DIRECT_PRICE) return [amount];

    dispatch(updateRefundInfo({ isGroupRefund: true }));

    const MAX_AMOUNT = 5_500_000;
    const result = [];

    while (amount > MAX_AMOUNT) {
      result.push(MAX_AMOUNT);
      amount -= MAX_AMOUNT;
    }

    if (amount > 0) {
      result.push(amount);
    }

    return result;
  };

  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (isScanned) {
      setDisableButton(true);
      const timer = setTimeout(() => {
        setDisableButton(false);
      }, 200);

      return () => clearTimeout(timer);
    } else {
      setDisableButton(false);
    }
  }, [isScanned]);

  const isDisabledOKButton = useMemo(() => {
    return disableButton;
  }, [disableButton]);

  return (
    <BackDrop>
      <Container
        onSubmit={(e) => {
          e.preventDefault();
          onClose(removeComma(getValues('totalPrice')) > DIRECT_PRICE);
        }}
      >
        <FlexWrap dir='column' alignItems='center'>
          <Typography
            size='21px'
            lineHeight='31.5px'
            fontWeight='700'
            fontFamily='Pretendard'
            color='#3A3B3E'
          >
            환급을 진행하시겠습니까?
          </Typography>
          <Typography size='14px' color='#80848A' margin='8px 0 0 0'>
            임시저장을 하시려면 [닫기]를 누른 후 페이지 맨 아래 [임시저장]
            버튼을 눌러주세요.
          </Typography>
        </FlexWrap>
        <DivideLine />
        <InputItems>
          <InputItem isSelectBox>
            <Item isSelectBox>
              <Typography
                size='16px'
                fontWeight='600'
                lineHeight='24px'
                color='#3A3B3E'
                margin='0 8px 0 0'
              >
                시술 항목
              </Typography>
              <SelectBox
                name='productType'
                selectValue={selectValue}
                setSelectValue={setSelectValue}
              />
            </Item>
            <Item>
              <Typography
                size='16px'
                lineHeight='24px'
                fontWeight='600'
                margin='0 24px 0 0'
                color='#3A3B3E'
              >
                시술 금액
              </Typography>
              <InputContainer isSelectBox>
                <Input
                  {...register(`totalPrice`, {})}
                  onChange={(e) => onChangeTotalPrice(e)}
                  placeholder='시술 금액 입력'
                  autoComplete='off'
                  isSelectBox
                />
                <Typography fontWeight='500' color='#5F6165' margin='0 0 0 8px'>
                  원
                </Typography>
              </InputContainer>
            </Item>
          </InputItem>

          {/* <InputItem margin='24px 0 16px 0'>
						<Item>
							<Typography fontWeight='600' margin='0 24px 0 0' color='#5F6165'>
								카드 결제
							</Typography>
							<InputContainer>
								<Input
									{...register(`paymentCard`)}
									autoComplete='off'
									readOnly
								/>
								<Typography fontWeight='500' color='#80848A' margin='0 0 0 8px'>
									원
								</Typography>
							</InputContainer>
						</Item>
					</InputItem>

					<InputItem>
						<Item>
							<Typography fontWeight='600' margin='0 24px 0 0' color='#5F6165'>
								현금 결제
							</Typography>
							<InputContainer isCash>
								<Input
									{...register(`paymentCash`)}
									autoComplete='off'
									placeholder='현금 결제 금액 입력'
									isCash
									onChange={onChangeCashPrice}
									onKeyDown={(e) => {
										if (e.key === 'Escape') {
											dispatch(kioskRefundModalState(false));
										}
									}}
								/>
								<Typography fontWeight='500' color='#5F6165' margin='0 0 0 8px'>
									원
								</Typography>
							</InputContainer>
							{errors.totalPrice && (
								<ErrorMessage>{String(errors.totalPrice.message)}</ErrorMessage>
							)}
						</Item>
					</InputItem> */}
        </InputItems>
        <DivideLine />
        <ButtonWrapper>
          <CloseButton type='button' onClick={() => onClose(false)}>
            닫기
          </CloseButton>
          <Button onClick={onClickClose} disabled={isDisabledOKButton}>
            확인
          </Button>
        </ButtonWrapper>
      </Container>
    </BackDrop>
  );
}

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const BackDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
`;
const Container = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 880px;
  /* height: 482px; */
  background-color: #fff;
  border-radius: 16px;
  padding: 32px;
`;
const DivideLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #a7a7a7;
  margin: 32px 0;
`;
const InputItems = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  position: relative;
`;
const InputItem = styled.div<{ isSelectBox?: boolean; margin?: string }>`
  display: flex;
  justify-content: ${(props) =>
    props.isSelectBox ? 'space-between' : 'flex-end'};
  margin: ${(props) => props.margin || '0'};
  ${(props) =>
    props.isSelectBox &&
    css`
      flex: 1;
    `}
`;
const Item = styled.div<{ isSelectBox?: boolean }>`
  display: flex;
  align-items: center;
  ${(props) =>
    props.isSelectBox &&
    css`
      flex: 1;
    `}
`;
const InputContainer = styled.div<{ isSelectBox?: boolean; isCash?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 250px;
  height: 44px;
  padding: 11px;
  border-radius: 4px;
  background-color: #f5f6f7;
  ${(props) =>
    props.isSelectBox &&
    css`
      border: 1.5px solid #246cf6;
      background-color: #f4f8ff;
    `}
  ${(props) =>
    props.isCash &&
    css`
      border: 1.5px solid #e5e6e8;
      background-color: #fff;
    `}
`;
const Input = styled.input<{ isSelectBox?: boolean; isCash?: boolean }>`
  height: 24px;
  text-align: right;
  outline: none;
  border: none;
  background-color: #f5f6f7;
  ${(props) =>
    props.isSelectBox &&
    css`
      background-color: #f4f8ff;
      ::placeholder {
        text-align: right;
        color: #030303;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }
    `}
  ${(props) =>
    props.isCash &&
    css`
      border: none;
      background-color: #fff;
    `}
`;
const Button = styled.button<{ isClose?: boolean }>`
  width: 335px;
  padding: 14px 0;
  color: #fff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  background-color: #246cf6;
  /* flex: 1.5; */
  :disabled {
    background-color: #d3e2fd;
  }
  ${(props) =>
    props.isClose &&
    css`
      flex: 1;
      color: #3a3b3e;
      background-color: #e5e6e8;
    `}
`;
const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  width: 335px;
  padding: 14px 0;
  color: #fff;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  background-color: #246cf6;
  flex: 1.5;
  flex: 1;
  color: #3a3b3e;
  background-color: #e5e6e8;
  cursor: pointer;
`;
const ErrorMessage = styled.p`
  position: absolute;
  bottom: -28px;
  right: 0;
  font-size: 12px;
  color: ${(props) => props.theme.priamryColors.error};
`;
export default PorteRefundModal;

import { ActiveAdPayload, AdResponse, SwitchAdPayload } from '@/types/ad.type';
import api from '..';

export const getAdList = async () => {
  const res = await api.getAxios<AdResponse, any>(`/user/advertisement`);
  return res.data;
};

export const uploadAd = async (payload: FormData) => {
  const res = await api.postAxios<null, FormData, null>(
    `/user/advertisement`,
    payload,
    null,
  );
  return res.data;
};

export const switchAdList = async (payload: SwitchAdPayload) => {
  const res = await api.patchAxios<null, SwitchAdPayload, null>(
    `/user/advertisement/priority`,
    payload,
  );
  return res.data;
};

export const activeAd = async (payload: ActiveAdPayload) => {
  const res = await api.patchAxios<null, ActiveAdPayload, null>(
    `/user/advertisement`,
    payload,
  );
  return res.data;
};

export const deleteAd = async (id: number) => {
  const res = await api.deleteAxios(`/user/advertisement/${id}`);
  return res.data;
};

import styled, { css } from 'styled-components';
import LoadingView from '@/components/common/LoadingView';
import { useMutation, useQueryClient } from 'react-query';
import { deleteAd } from '@/api/ad';

type Props = {
  id: number;
  popUpName: string;
  footerName: string;
  webPageUrl: string;
  onClose: () => void;
};
const DeleteModal = ({
  id,
  popUpName,
  footerName,
  webPageUrl,
  onClose,
}: Props) => {
  const queryClient = useQueryClient();
  const { mutate: onDelete, isLoading } = useMutation(
    (id: number) => deleteAd(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getAdList');
        onClose();
      },
      onError: (error: any) => {
        console.error(error);
      },
    },
  );

  return (
    <BackDrop>
      {isLoading ? (
        <LoadingContainer>
          <LoadingView />
        </LoadingContainer>
      ) : (
        <Container>
          <Title>광고 삭제하기</Title>
          <Content>광고 배너를 삭제합니다.</Content>
          <FileName>
            {footerName}
            <br />
            {popUpName || '팝업 배너 없음'}
            <br />
            {webPageUrl || '링크 없음'}
          </FileName>
          <ButtonWrapper>
            <Button onClick={onClose}>취소</Button>
            <Button colored onClick={() => onDelete(id)}>
              삭제하기
            </Button>
          </ButtonWrapper>
        </Container>
      )}
    </BackDrop>
  );
};

export default DeleteModal;

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: rgba(33, 33, 33, 0.1);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 600px;
  padding: 36px 40px;
  position: relative;
  border-radius: 8px;
  background-color: ${(props) => props.theme.pointColors.white};
  filter: ${(props) => props.theme.boxShadow};
`;

const LoadingContainer = styled(Container)`
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 21px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 16px;
`;

const Content = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #5f6165;
`;

const FileName = styled.div`
  color: #5f6165;
  font-size: 12px;
  padding: 12px;
  width: 100%;
  background-color: #f5f6f7;
  border-radius: 4px;
  margin-top: 16px;
  position: relative;
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 18px;
  margin-top: 16px;
`;

const Button = styled.button<{ colored?: boolean }>`
  ${(props) =>
    props.colored
      ? css`
          background-color: #ff2d55;
          color: #ffffff;
        `
      : css`
          background-color: #e5e6e8;
          color: #3a3b3e;
        `}
  font-size: 14px;
  line-height: 150%;
  padding: 15px 42px;
  font-weight: 600;
  border-radius: 6px;
  flex: 1;
  :hover {
    opacity: 0.6;
  }
`;

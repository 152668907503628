import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PortePayment from './PortePayment';
import {
  Payment,
  PaymentMethod,
  PaymentPrice,
  UnitText,
} from '../AmountDetail';
import { getComma, removeComma } from '@/util/format';
import { getRefundAmount } from '@/util/getRefundAmount';

type Props = {
  supplyList: {
    productType: string;
    price: string;
    supplyPrice: string;
    vatPrice: string;
    isCheck: boolean;
  }[];
  totalPayment: number;
};

const PortePaymentList = ({ supplyList, totalPayment }: Props) => {
  const totalRefund = getRefundAmount(totalPayment);
  let newRefund = totalRefund;
  const data = supplyList.filter((supply) => supply.price);
  const [card, setCard] = useState({});
  const [cash, setCash] = useState({});

  const getTotalPrice = (object: {}) => {
    const values = Object.values(object);
    const sum = values.reduce((acc, cur) => {
      return Number(acc) + Number(cur);
    }, 0);

    return getComma(Number(sum));
  };

  return (
    <PortePaymentWrapper>
      {data &&
        totalRefund &&
        data.map((supply, index) => {
          const supplyPrice = removeComma(supply.price);
          const refundPrice = getRefundAmount(supplyPrice);
          newRefund = newRefund - refundPrice;
          if (supply.price && supply.price !== '0') {
            return (
              <PortePayment
                key={index}
                index={index}
                data={supply}
                isLast={data.length === index + 1}
                refund={newRefund}
                setCard={setCard}
                setCash={setCash}
              />
            );
          }
        })}
      <Hr />
      <Wrapper>
        <PaymentMethod>총 시술 금액</PaymentMethod>
        <Payment isFullPayment>
          <PaymentPrice readOnly type='text' value={getComma(totalPayment)} />
          <UnitText>원</UnitText>
        </Payment>
        <Wrapper>
          <Payment isFullPayment>
            <PaymentMethod>환급액</PaymentMethod>
            <PaymentPrice readOnly type='text' value={getComma(totalRefund)} />
            <UnitText>원</UnitText>
          </Payment>
          <Payment isFullPayment>
            <PaymentMethod>카드 결제</PaymentMethod>
            <PaymentPrice readOnly type='text' value={getTotalPrice(card)} />
            <UnitText>원</UnitText>
          </Payment>
          <Payment>
            <PaymentMethod>현금 결제</PaymentMethod>
            <PaymentPrice
              type='text'
              readOnly
              onChange={() => {}}
              value={getTotalPrice(cash)}
              placeholder='현금 결제금액 입력'
            />
            <UnitText>원</UnitText>
          </Payment>
        </Wrapper>
      </Wrapper>
    </PortePaymentWrapper>
  );
};

export default PortePaymentList;

const PortePaymentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #ffffff;
  padding: 16px;
  border-radius: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 16px;
  @media ${(props) => props.theme.largeView} {
    padding: 24px 32px;
  }
`;

const Hr = styled.hr`
  border: 1px solid #e5e6e8;
  width: 100%;
`;

import { Button, Icon, Modal } from '@successmode-ktp/kui';
import styled from 'styled-components';
import ICON_QUESTION from '@/assets/common/icon_question.png';
import { FormEvent, useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { uploadAd } from '@/api/ad';
import {
  FOOTER_BANNER_MAX_RATIO,
  FOOTER_BANNER_MIN_RATIO,
  POPUP_BANNER_MAX_RATIO,
  POPUP_BANNER_MIN_RATIO,
} from '@/constants/banner.const';

type Props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
};

const UploadModal = ({ isOpen, setIsOpen }: Props) => {
  const [footerBanner, setFooterBanner] = useState<File>();
  const [popUpBanner, setPopUpBanner] = useState<File>();
  const [webPageUrl, setWebPageUrl] = useState<string>('');
  const queryClient = useQueryClient();

  useEffect(() => {
    if (!isOpen) {
      setFooterBanner(undefined);
      setPopUpBanner(undefined);
      setWebPageUrl('');
    }
  }, [isOpen]);

  const isValidRatio = (
    type: 'footer' | 'popUp',
    maxRatio: number,
    minRatio: number,
    denominator: number,
    molecule: number,
  ) => {
    const calulateRatio = Number((denominator / molecule).toFixed(2));

    if (calulateRatio > maxRatio || calulateRatio < minRatio) {
      if (type === 'footer') {
        alert(
          `이미지 비율을 확인해주세요.\n(${minRatio} : 1 ~ ${maxRatio} : 1 비율로 업로드해주세요.)`,
        );
      } else {
        alert(
          `이미지 비율을 확인해주세요.\n(1 : ${minRatio} ~ 1 : ${maxRatio} 비율로 업로드해주세요.)`,
        );
      }
      return false;
    }
    return true;
  };

  const selectBanner = (files: FileList | null, type: 'footer' | 'popUp') => {
    if (!files) return;
    const file = files[0];

    if (!file) return;
    const img = new Image();
    const fileUrl = URL.createObjectURL(file);

    img.onload = () => {
      URL.revokeObjectURL(fileUrl);
      if (type === 'footer') {
        isValidRatio(
          type,
          FOOTER_BANNER_MAX_RATIO,
          FOOTER_BANNER_MIN_RATIO,
          img.width,
          img.height,
        ) && setFooterBanner(file);
      } else {
        isValidRatio(
          type,
          POPUP_BANNER_MAX_RATIO,
          POPUP_BANNER_MIN_RATIO,
          img.height,
          img.width,
        ) && setPopUpBanner(file);
      }
    };
    img.src = fileUrl;
  };

  const { mutate, isLoading } = useMutation(
    (payload: FormData) => uploadAd(payload),
    {
      onSuccess: () => {
        setIsOpen(false);
        queryClient.invalidateQueries('getAdList');
      },
      onError: (error: any) => {
        console.error(error);
      },
    },
  );

  const uploadBanner = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('footerFile', footerBanner as Blob);
    formData.append('popUpFile', popUpBanner as Blob);
    formData.append('webPageUrl', webPageUrl);

    mutate(formData);
  };

  return (
    <BackDrop>
      <Modal isOpen={isOpen}>
        <Modal.Header>
          <ModalHeaderWrapper>
            <span>배너 광고 업로드</span>
            <button type='button' onClick={() => setIsOpen(false)}>
              <Icon.X />
            </button>
          </ModalHeaderWrapper>
        </Modal.Header>
        <ModalBodyWrapper id='banner' onSubmit={uploadBanner}>
          <InputWrapper>
            <Label>
              하단배너광고 (360*80px) <Required>*</Required>
            </Label>
            <InputBox>
              <Placeholder $isFile={!!footerBanner}>
                {footerBanner?.name || '이미지를 첨부해주세요.'}
              </Placeholder>
              <FileBadge>파일 선택</FileBadge>
            </InputBox>
            <Input
              type='file'
              name='footerFile'
              accept='image/*'
              onChange={(e) => {
                selectBanner(e.target.files, 'footer');
                e.target.value = '';
              }}
            />
            {footerBanner && (
              <ImgDeleteButton
                onClick={(e) => {
                  e.preventDefault();
                  setFooterBanner(undefined);
                }}
              >
                <Icon.XFilled fillColor='#E5E6E8' />
              </ImgDeleteButton>
            )}
          </InputWrapper>
          <InputWrapper>
            <Label>팝업광고 이미지 (360*540px)</Label>
            <InputBox>
              <Placeholder $isFile={!!popUpBanner}>
                {popUpBanner?.name || '이미지를 첨부해주세요.'}
              </Placeholder>
              <FileBadge>파일 선택</FileBadge>
            </InputBox>
            <Input
              type='file'
              name='popUpFile'
              accept='image/*'
              onChange={(e) => {
                selectBanner(e.target.files, 'popUp');
                e.target.value = '';
              }}
            />
            <GuideText>
              <img src={ICON_QUESTION} alt='' width={14} />
              하단배너 광고를 클릭했을 때 팝업되는 광고 이미지
            </GuideText>
            {popUpBanner && (
              <ImgDeleteButton
                onClick={(e) => {
                  e.preventDefault();
                  setPopUpBanner(undefined);
                }}
              >
                <Icon.XFilled fillColor='#E5E6E8' />
              </ImgDeleteButton>
            )}
          </InputWrapper>
          <InputWrapper>
            <Label>URL 추가</Label>
            <UrlInput
              type='text'
              name='webPageUrl'
              placeholder='ex) https://ktaxpay.com'
              onChange={(e) => setWebPageUrl(e.target.value)}
            />
            <GuideText>
              <img src={ICON_QUESTION} alt='' width={14} />
              배너 광고를 클릭했을 때 이동되는 URL 링크
            </GuideText>
          </InputWrapper>
        </ModalBodyWrapper>
        <Modal.Footer>
          <Button theme='mono' onClick={() => setIsOpen(false)}>
            닫기
          </Button>
          <Button
            type='submit'
            form='banner'
            disabled={!footerBanner}
            loading={isLoading}
          >
            저장하기
          </Button>
        </Modal.Footer>
      </Modal>
    </BackDrop>
  );
};

export default UploadModal;

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(33, 33, 33, 0.5);
`;

const ModalHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const ModalBodyWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 16px;
`;

const InputWrapper = styled.label`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InputBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--KTP-Grayscale-Gray_09, #ccc);
  padding: 16px;
  color: var(--KTP-Grayscale-Gray_09, #ccc);
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.28px;
  width: 400px;
`;

const Placeholder = styled.span<{ $isFile: boolean }>`
  color: ${({ $isFile }) =>
    $isFile
      ? 'var(--KTP-Grayscale-Gray_02, #111)'
      : 'var(--KTP-Grayscale-Gray_09, #ccc)'};
`;

const FileBadge = styled.span`
  padding: 2px 12px;
  border-radius: 8px;
  border: 1px solid var(--KTP-Grayscale-Gray_09, #ccc);
  background: var(--KTP-Grayscale-Gray_12, #f4f4f4);
  color: var(--KTP-Grayscale-Gray_02, #111);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: -0.24px;
`;

const Input = styled.input`
  display: none;
`;

const UrlInput = styled.input`
  border-radius: 6px;
  border: 1px solid var(--KTP-Grayscale-Gray_09, #ccc);
  padding: 16px;
  width: 400px;
  &::placeholder {
    color: var(--KTP-Grayscale-Gray_09, #ccc);
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: -0.28px;
  }
`;

const Label = styled.span`
  color: var(--Mono-65, #5f6165);
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.28px;
`;

const GuideText = styled.small`
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--KTP-Grayscale-Gray_07, #999);
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.24px;
`;

const Required = styled.span`
  color: var(--Red-100, #ed0828);
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
  letter-spacing: -0.28px;
`;

const ImgDeleteButton = styled.div`
  position: absolute;
  top: 48px;
  right: 90px;
`;

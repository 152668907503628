import styled from 'styled-components';
import Upload from '@/assets/kiosk/upload.png';
import FlexWrap from '@/components/common/FlexWrap';
import { Button } from '@successmode-ktp/kui';
import { useState } from 'react';
import UploadModal from '@/components/adSetting/Upload.modal';
import { getAdList } from '@/api/ad';
import { useQuery } from 'react-query';
import ActivateTable from '@/components/adSetting/ActivateTable';
import InActivateTable from '@/components/adSetting/InActivateTable';
import LoadingView from '@/components/common/LoadingView';

const AdSetting = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data, isLoading } = useQuery('getAdList', getAdList, {
    retry: 1,
  });

  return (
    <Container>
      <Title>배너 광고 설정</Title>
      {isLoading ? (
        <LoadingView height='50vh' />
      ) : (
        <>
          <ActivateTable data={data?.activatedAds || []} />
          <InActivateTable data={data?.deActivatedAds || []} />
        </>
      )}
      <BtnContainer>
        <Button style={{ width: '160px' }} onClick={() => setIsOpen(true)}>
          <BtnIcon src={Upload} />
          배너 광고 업로드
        </Button>
      </BtnContainer>
      {isOpen && <UploadModal isOpen={isOpen} setIsOpen={setIsOpen} />}
    </Container>
  );
};
export default AdSetting;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1080px;
  height: 100vh;
  flex: 1;
  overflow-y: scroll;
  background-color: ${(props) => props.theme.blueColors.scale3};
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
  padding: 72px 40px 24px;
`;

const BtnContainer = styled(FlexWrap)`
  gap: 20px;
  justify-content: flex-end;
  margin-right: 40px;
`;

const BtnIcon = styled.img`
  width: 20px;
  height: 20px;
`;

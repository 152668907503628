import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  isSameDay,
} from 'date-fns';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range: any) {
    //@ts-ignore
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges: any) {
  //@ts-ignore
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const defaultStaticRanges = createStaticRanges([
  {
    label: '오늘',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: '어제',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: '이번 주',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: '지난 주',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: '이번 달',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: '지난 달',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: '지난 7일',
    range: () => ({
      startDate: addDays(
        defineds.startOfToday,
        (Math.max(Number(7), 1) - 1) * -1,
      ),
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: '지난 30일',
    range: () => ({
      startDate: addDays(
        defineds.startOfToday,
        (Math.max(Number(30), 1) - 1) * -1,
      ),
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: '지난 180일',
    range: () => ({
      startDate: addDays(
        defineds.startOfToday,
        (Math.max(Number(180), 1) - 1) * -1,
      ),
      endDate: defineds.endOfToday,
    }),
  },
]);

export const getLatestYear = (startYear: number, endYear: number) => {
  let result = [];
  for (let i = startYear; i <= endYear; i++) {
    result.push({
      value: i.toString(),
      label: `${i}년`,
    });
  }

  return result;
};
export const yearData = getLatestYear(
  new Date().getFullYear() - 10,
  new Date().getFullYear(),
);
export const monthData = [
  { value: '01', label: '1월' },
  { value: '02', label: '2월' },
  { value: '03', label: '3월' },
  { value: '04', label: '4월' },
  { value: '05', label: '5월' },
  { value: '06', label: '6월' },
  { value: '07', label: '7월' },
  { value: '08', label: '8월' },
  { value: '09', label: '9월' },
  { value: '10', label: '10월' },
  { value: '11', label: '11월' },
  { value: '12', label: '12월' },
];

// ex) 11/5~12/4 => 10월, 12/5~1/4 => 11월
export const getPreviousDate = (date: Date) => {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;

  if (year === 2024 && month <= 10) {
    return { year: 2024, month: 10 };
  }

  if (date.getDate() >= 5) {
    if (month === 1) {
      year -= 1;
      month = 12;
    } else {
      month -= 1;
    }
  } else {
    if (date.getMonth() <= 2) {
      year -= 1;
      month = month === 1 ? 11 : 12;
    } else {
      month -= 2;
    }
  }
  return { year, month };
};

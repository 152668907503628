import { Icon, Modal, Pagination } from '@successmode-ktp/kui';
import styled from 'styled-components';
import { comma } from '@/util/format';
import TableHeader from '../refund/PaymentComplete/TableHeader';
import refundNotImage from '@/assets/refund/refundNot.png';
import { useQuery } from 'react-query';
import { refundDepartureDetailResponse } from '@/types/refund';
import { getRefundDepartureDetail } from '@/api/refund';
import { useState } from 'react';
import { format } from 'date-fns';
import { RefundDepartureDetailListHeader } from '@/constants/refund';
import DataDivision from './DataDivision';
import SearchBar from '../refundDetail/SearchBar';

type Props = {
  date: string | null;
  detailDate: string | null;
  isOpen: boolean;
  onClose: () => void;
  keywords?: { label: string }[];
};

function RefundDetailModal({ date, detailDate, isOpen, onClose }: Props) {
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [isFirstPage, setIsFirstPage] = useState(false);
  const [isLastPage, setIsLastPage] = useState(false);
  const [keyword, setKeyword] = useState('');

  const { data } = useQuery<refundDepartureDetailResponse>(
    ['refundDepartureDetail', currentPage, keyword],
    () =>
      getRefundDepartureDetail({
        date,
        detailDate: detailDate || '',
        page: currentPage,
        size: 10,
        keyword,
      }),
    {
      onSuccess: (data) => {
        setTotalPage(data.details.totalPages);
        setIsFirstPage(data.details.first);
        setIsLastPage(data.details.last);
      },
      keepPreviousData: true,
    },
  );

  const RefundData = [
    {
      label: '외국인 환자 택스리펀 건',
      value: data?.count || 0,
      unit: '건',
      icon: <Icon.My fillColor='#5089F8' />,
    },
    {
      label: '환급액',
      value: data?.totalRefund || 0,
      unit: '원',
      icon: <Icon.Refund fillColor='#5089F8' size={28} />,
    },
  ];

  return (
    <Modal isOpen={isOpen && !!data} hasBackdrop onClickBackdrop={onClose}>
      <Modal.Header>
        <h3>
          {detailDate
            ? `${Number(format(new Date(detailDate), 'MM'))}월 출국`
            : '출국 확인중'}{' '}
          건 상세보기
        </h3>
        <CloseButton onClick={onClose}>
          <Icon.X size={16} fillColor='#5F6165' />
        </CloseButton>
      </Modal.Header>
      <ModalBody>
        <SearchBar setKeyword={setKeyword} />
        <DataDivision data={RefundData} />
        <Table>
          <TableHeader
            titleList={RefundDepartureDetailListHeader}
            hasNotOption
          />
          {data && data.details.content.length > 0 ? (
            <List>
              {data.details.content.map((d, index) => (
                <Item key={index}>
                  <Content flex={0.7}>{index + 1}</Content>
                  <Content flex={1.8}>
                    {d.refundHistoryStatus === 'DEPARTURE_CHECK'
                      ? '출국 확인중'
                      : '승인'}
                  </Content>
                  <Content flex={2}>{d.supplyDate}</Content>
                  <Content flex={1.5}>{d.nation}</Content>
                  <Content flex={3.5}>{d.touristName}</Content>
                  <Content flex={3}>{comma(d.price)}원</Content>
                  <Content flex={2}>{comma(d.refund)}원</Content>
                </Item>
              ))}
            </List>
          ) : (
            <RefundNotContainer>
              <Image src={refundNotImage} />
              <Text>검색 결과가 없습니다.</Text>
            </RefundNotContainer>
          )}
        </Table>
      </ModalBody>
      {totalPage > 0 && (
        <Pagination
          page={currentPage + 1}
          setPage={(page) => setCurrentPage(page - 1)}
          totalPage={totalPage}
          isFirstPage={isFirstPage}
          isLastPage={isLastPage}
        />
      )}
    </Modal>
  );
}

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
  width: 90vw;
  max-width: 1512px;
  margin: 24px 0;
`;

const CloseButton = styled.button`
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;
`;

const Table = styled.div`
  width: 100%;

  & > div {
    margin: 0;
  }
`;

const List = styled.ul``;

const Image = styled.img`
  width: 72px;
  height: 72px;
`;

const Text = styled.span`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[50]};
`;

const RefundNotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1;
  padding: 24px 0;
`;

const Item = styled.li`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.mono[10]};
  background-color: #fff;
  font-weight: 500;
  color: #3a3b3e;
  height: 80px;
`;

const Content = styled.div<{
  flex: number;
}>`
  display: flex;
  flex: ${(props) => props.flex};
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  font-size: 14px;
  line-height: 24px;
`;

export default RefundDetailModal;

import { SupplyHeader } from '@/constants/supply';
import styled from 'styled-components';
import SupplyInput from './SupplyInput';
import { Dispatch, SetStateAction } from 'react';
import { Button, Icon } from '@successmode-ktp/kui';
import { useDispatch } from 'react-redux';
import {
  setIsOpenCustomRefundModal,
  updateRefundInfo,
} from '@/store/modules/refund';
import { useAppSelector } from '@/hooks/useReduxHooks';
import { useFormContext } from 'react-hook-form';

type Props = {
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  isCustomKor?: boolean;
};

function SupplyDetail({ setIsOpen, isCustomKor = false }: Props) {
  // const methods = useFormContext();

  // const resetRefund = () => {
  // 	const supplyList = methods.getValues('supply') || [];
  // 	supplyList.map((supply: any, index: number) => {
  // 		methods.setValue(`supply.${index}.price`, '');
  // 		methods.setValue(`supply.${index}.productType`, '');
  // 		methods.setValue(`supply.${index}.supplyPrice`, '');
  // 		methods.setValue(`supply.${index}.vatPrice`, '');
  // 	});
  // 	methods.setValue('porteRefund', []);
  // };

  const dispatch = useDispatch();
  const { isGroupRefund } = useAppSelector((state) => state.refund);
  const methods = useFormContext();

  const onOpenGroupRefundModal = () => {
    dispatch(updateRefundInfo({ isGroupRefund: false }));
    const supplyList = methods.getValues('supply') || [];
    const updatedSupply = [...supplyList].splice(0, 3);
    updatedSupply.map((supply: any, index: number) => {
      methods.setValue(`supply.${index}.price`, '');
      methods.setValue(`supply.${index}.productType`, '');
      methods.setValue(`supply.${index}.supplyPrice`, '');
      methods.setValue(`supply.${index}.vatPrice`, '');
    });
    methods.setValue('supply', updatedSupply);
    methods.setValue('porteRefund', []);
    dispatch(setIsOpenCustomRefundModal(true));
  };

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Title>용역 공급 내역</Title>
        {isGroupRefund && (
          <Button
            size='sm'
            style={{
              width: '104px',
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '10px',
            }}
            onClick={onOpenGroupRefundModal}
          >
            <Icon.Reload fillColor='var(--Gray-14)' size={16} />
            <span>금액수정</span>
          </Button>
        )}
      </div>
      <SupplyContainer>
        <Header>
          {SupplyHeader.map((item) => (
            <HeaderTitle
              key={item.id}
              flex={item.flex}
              highlight={item.highlight || false}
            >
              {item.title}
            </HeaderTitle>
          ))}
        </Header>
        <SupplyInput isCustomKor={isCustomKor} />
      </SupplyContainer>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
`;

const SupplyContainer = styled.div`
  margin-top: 16px;
  background-color: ${(props) => props.theme.pointColors.white};
  border-radius: 16px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${(props) => props.theme.mono[20]};
`;

const HeaderTitle = styled.div<{ flex: number; highlight?: boolean }>`
  display: flex;
  justify-content: center;
  padding: 12px 0;
  flex: ${(props) => props.flex};
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.small};
  color: ${(props) =>
    props.highlight ? props.theme.mono[65] : props.theme.mono[35]};
`;

export default SupplyDetail;

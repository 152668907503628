import styled from 'styled-components';

import CloseIcon from '@/assets/common/cancle.png';
import FlexWrap from '@/components/common/FlexWrap';

type Props = {
  popUpUrl: string;
  footerUrl: string;
  webPageUrl: string;
  onClose: () => void;
};
const PreviewModal = ({ popUpUrl, footerUrl, webPageUrl, onClose }: Props) => {
  return (
    <BackDrop>
      <Container>
        <FlexWrap justifyContent='space-between'>
          <Title>배너 미리보기</Title>
          <CloseBtn onClick={onClose}>
            <CloseImg src={CloseIcon} />
          </CloseBtn>
        </FlexWrap>
        <PreviewWrapper>
          <BannerWrapper>
            <Preview>
              <Img src={footerUrl} alt='footer' />
            </Preview>
          </BannerWrapper>
          <BannerWrapper>
            <Banner>
              {popUpUrl ? <Img src={popUpUrl} alt='popUp' /> : '팝업 배너 없음'}
            </Banner>
          </BannerWrapper>
        </PreviewWrapper>
        <UrlLink>
          URL:{' '}
          {webPageUrl ? (
            <Link href={webPageUrl} target='_blank'>
              {webPageUrl}
            </Link>
          ) : (
            '링크 없음'
          )}
        </UrlLink>
      </Container>
    </BackDrop>
  );
};

export default PreviewModal;

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: rgba(33, 33, 33, 0.5);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 36px;
  position: relative;
  border-radius: 8px;
  background-color: ${(props) => props.theme.pointColors.white};
  filter: ${(props) => props.theme.boxShadow};
`;

const Title = styled.h3`
  font-size: 21px;
  color: #030303;
  font-weight: 700;
  margin-bottom: 12px;
`;
const CloseBtn = styled.button``;

const CloseImg = styled.img`
  width: 16px;
  height: 16px;
`;

const UrlLink = styled.p`
  font-size: 14px;
  margin: 12px 0 0;
  color: #5f6165;
  background: var(--Mono-05, #f5f6f7);
  padding: 6px;
`;

const PreviewWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 375px;
  height: 600px;
  background: var(--Mono-05, #f5f6f7);
`;

const Preview = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5f6165;
  width: 100%;
  background: var(--Mono-20, #cbccce);
`;

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #5f6165;
  background: var(--Mono-20, #cbccce);
  overflow: hidden;
`;

const Img = styled.img`
  object-fit: fill;
  width: 100%;
`;

const Link = styled.a`
  color: #246cf6;
  text-decoration: underline;
`;

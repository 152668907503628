import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { SideNavigationInfo } from '@/constants/sideNavigation';
import SideMenuItem from './SideMenuItem';
import Naviagtion from '../navigation';

import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';

import defaultProfileIcon from '@/assets/sideBar/default_profile.png';
import {
  axiosRequestConfiguration,
  KIOSK_SERVER_TEST,
  SERVER_TEST,
} from '@/api/config';
import { validateReset } from '@/store/modules/validate';
import { reset } from '@/store/modules/refund';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { logOut } from '@/api/user';
import { loginUserState } from '@/store/modules/user';
import CashBillModal from './CashBillModal';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';
import WarningIcon from '@/assets/common/error.png';
import PassportCheckModal from './PassportCheckModal';

function SideNavigation() {
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const name = localStorage.getItem('name');
  const profileImg = localStorage.getItem('profileImg');
  const [isTestServer, setIsTestServer] = useState(false);
  const [isOpenCashBillModal, setIsOpenCashBillModal] = useState(false);
  const { isKioskStore, adEnabled } = useAppSelector((state) => state.user);
  const { isPassportCheck } = useAppSelector((state) => state.validate);
  const accessToken = localStorage.getItem('loginToken');
  const kioskPermission = localStorage.getItem('kioskPermission');
  const { globalStatus } = useGetGlobalStatus(location.pathname);

  const isTestServerURL = (baseURL: string) => {
    return baseURL === SERVER_TEST || baseURL === KIOSK_SERVER_TEST;
  };

  useEffect(() => {
    const currentBaseURL: string = axiosRequestConfiguration.baseURL!;
    if (isTestServerURL(currentBaseURL)) {
      setIsTestServer(true);
    }
  }, []);

  useEffect(() => {
    dispatch(validateReset());
    dispatch(reset());
  }, [location]);

  const { refetch } = useQuery<null, AxiosError>('logOut', logOut, {
    enabled: false,
    retry: false,
    onSuccess: () => {
      localStorage.setItem('loginToken', '');
      dispatch(loginUserState(false));
      navigate('/');
    },
    onError: (error) => {
      console.log('에러', error);
    },
  });

  const [clickedLink, setClickedLink] = useState('');

  const onOpenPassportCheck = (event: React.MouseEvent, link: string) => {
    event.preventDefault();
    if (isPassportCheck) {
      setClickedLink(link);
    } else {
      window.location.href = link;
    }
  };

  return (
    <>
      <Wrapper isTestServer={isTestServer}>
        <Container>
          <Naviagtion isTestServer={isTestServer} />
          <Profile>
            <ProfileImage
              src={profileImg ? profileImg : defaultProfileIcon}
              alt='프로필.'
              width='48px'
              height='48px'
            />
            <ProfileContent>
              안녕하세요.
              <br />
              <Bold>{name}</Bold> 님
            </ProfileContent>
          </Profile>
          <List>
            {SideNavigationInfo.map((item) => {
              if (!adEnabled && item.id === 6) {
                return null;
              }
              return (
                <LinkButton
                  key={item.id}
                  onClick={(e) => onOpenPassportCheck(e, item.link[0])}
                >
                  {item.id === 5 &&
                  (!isKioskStore || kioskPermission === 'USER') ? (
                    <></>
                  ) : (
                    <SideMenuItem menuItem={item} />
                  )}
                </LinkButton>
              );
            })}
          </List>
        </Container>
        <BtnWrapper>
          {accessToken &&
            !globalStatus?.isCashBillAgreed &&
            globalStatus?.paymentType === 'ORIGINAL' &&
            globalStatus?.isBeforeNovember && (
              <>
                <WarnIcon src={WarningIcon} alt='' width={24} />
                <LinkBtn onClick={() => setIsOpenCashBillModal(true)}>
                  환급금 현금영수증 자동 발행
                </LinkBtn>
              </>
            )}
        </BtnWrapper>
      </Wrapper>
      {isOpenCashBillModal && (
        <CashBillModal
          onClose={() => setIsOpenCashBillModal(false)}
          onCancel={() => setIsOpenCashBillModal(false)}
        />
      )}
      <PassportCheckModal
        isOpen={!!clickedLink}
        onClose={() => setClickedLink('')}
        link={clickedLink}
      />
    </>
  );
}

const Wrapper = styled.div<{ isTestServer: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 40px;
  position: relative;
  top: 0px;
  width: 200px;
  min-height: 100vh;
  background-color: ${(props) =>
    props.isTestServer ? 'green' : props.theme.blue[150]};
  z-index: 10;
  @media ${(props) => props.theme.largeView} {
    width: 280px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const List = styled.ul`
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
`;

const Profile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 28px 32px;
`;

const ProfileImage = styled.img`
  object-fit: cover;
  border-radius: 100px;
`;

const ProfileContent = styled.span`
  font-size: ${(props) => props.theme.fontSize.small};
  line-height: ${(props) => props.theme.lineHeight.small};
  color: ${(props) => props.theme.blue[20]};
  font-weight: 400;
`;

const Bold = styled.span`
  font-size: ${(props) => props.theme.fontSize.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  color: ${(props) => props.theme.pointColors.white};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.small};
    line-height: ${(props) => props.theme.lineHeight.small};
  }
`;
const BtnWrapper = styled.div`
  padding: 20px;
  width: 100%;
  position: relative;
`;
const LinkBtn = styled.button`
  display: block;
  width: 100%;
  color: #a7c4fb;
  border: 1px solid #a7c4fb;
  padding: 12px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  :hover {
    opacity: 0.7;
  }
  @media ${(props) => props.theme.largeView} {
    padding: 14px;
  }
`;

const WarnIcon = styled.img`
  position: absolute;
  top: 6px;
  left: 32px;
`;

const LinkButton = styled.button`
  padding: 0;
`;

export default SideNavigation;

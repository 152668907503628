import React, { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';

import SupplyInputBtn from './SupplyInputBtn';
import SelectBox from '@/components/common/SelectBox';
import { useAppSelector } from '@/hooks/useReduxHooks';
import {
  getComma,
  getSupplyPaymentPrice,
  getVatPaymentPrice,
  removeComma,
} from '@/util/format';
import { DIRECT_PRICE, isCustom } from '@/constants/common';
import { useDispatch } from 'react-redux';
import {
  setIsOpenCustomRefundModal,
  updateRefundInfo,
} from '@/store/modules/refund';
import { Icon, Modal } from '@successmode-ktp/kui';

interface IProps {
  index: number;
  onRemove: (index: number) => void;
  isCustomKor?: boolean;
}

interface IChange {
  index: number;
  e: React.ChangeEvent<HTMLInputElement>;
}

function SupplyInputItem({ index, onRemove, isCustomKor = false }: IProps) {
  const methods = useFormContext();
  const dispatch = useDispatch();
  const { hospitalId } = useAppSelector((state) => state.user);
  const isSurgery = String(methods.getValues(`supply.${index}.productType`));
  const { temporaryStorage, isGroupRefund } = useAppSelector(
    (state) => state.refund,
  );

  const [isOpen600OverModal, setIsOpen600OverModal] = useState(false);

  // 임시저장
  useEffect(() => {
    if (temporaryStorage.supplyDate) {
      let newValue = '';
      temporaryStorage.supply.forEach((item: any, idx) => {
        if (index === idx) {
          newValue = item.price;
        }
      });
      methods.setValue(`supply.${index}.price`, getComma(newValue));
      methods.setValue(
        `supply.${index}.supplyPrice`,
        getComma(getSupplyPaymentPrice(newValue.replace(/(,)/g, ''))),
      );
      methods.setValue(
        `supply.${index}.vatPrice`,
        getComma(
          getVatPaymentPrice(
            newValue.replace(/(,)/g, ''),
            getSupplyPaymentPrice(newValue.replace(/(,)/g, '')).toString(),
          ),
        ),
      );
    }
  }, [temporaryStorage]);

  useEffect(() => {
    if (isSurgery) {
      setTimeout(() => {
        methods.setFocus(`supply.${index}.price`);
      }, 100);
    }
  }, [isSurgery]);

  const lastValidPrice = useRef('');
  const onChange = ({ e: { target }, index }: IChange) => {
    // 금액이 0으로 시작하는거 방지
    const newtarget =
      target.value.charAt(0) === '0' ? target.value.substring(1) : target.value;
    methods.setValue(`supply.${index}.price`, newtarget.replace(/[^0-9]/g, ''));

    let newValue = target.value.replace(/(,)/g, '');
    if (+newValue === 0) {
      // 결제금액
      methods.setValue(`supply.${index}.price`, '0');
      // 공급가액
      methods.setValue(`supply.${index}.supplyPrice`, '0');
      // 부가세
      methods.setValue(`supply.${index}.vatPrice`, '0');
    } else {
      // 결제금액
      methods.setValue(`supply.${index}.price`, getComma(newValue));
      lastValidPrice.current = methods.getValues(`supply.${index}.price`);
      // 공급가액
      methods.setValue(
        `supply.${index}.supplyPrice`,
        getComma(getSupplyPaymentPrice(newValue)),
      );
      // 부가세
      methods.setValue(
        `supply.${index}.vatPrice`,
        getComma(
          getVatPaymentPrice(
            newValue,
            getSupplyPaymentPrice(newValue).toString(),
          ),
        ),
      );
    }

    if (isCustom(hospitalId)) {
      checkGroupRefund();
    }
  };

  const checkGroupRefund = () => {
    const supplyList = methods.getValues('supply');
    const sumRefund = supplyList.reduce((acc: any, supply: any) => {
      return (acc += removeComma(supply.price));
    }, 0);

    if (sumRefund > DIRECT_PRICE && !isCustomKor) {
      setIsOpen600OverModal(true);
    }
  };

  const onChangePrice = ({ e, index }: IChange) => {
    onChange({ e, index });
  };

  const onReset = () => {
    methods.setValue(`supply.${index}.price`, '');
    methods.setValue(`supply.${index}.productType`, '');
    methods.setValue(`supply.${index}.supplyPrice`, '');
    methods.setValue(`supply.${index}.vatPrice`, '');
  };

  const onOpenGroupRefundModal = () => {
    const supplyList = methods.getValues('supply');
    setIsOpen600OverModal(false);
    dispatch(setIsOpenCustomRefundModal(true));
    dispatch(updateRefundInfo({ isGroupRefund: false }));
    const updatedSupply = [...supplyList].splice(0, 3);
    updatedSupply.map((supply: any, index: number) => {
      methods.setValue(`supply.${index}.price`, '');
      methods.setValue(`supply.${index}.productType`, '');
      methods.setValue(`supply.${index}.supplyPrice`, '');
      methods.setValue(`supply.${index}.vatPrice`, '');
    });
    methods.setValue('supply', updatedSupply);
    methods.setValue('porteRefund', []);
    resetSupplyList(supplyList.length);
  };

  const resetSupplyList = (length: number) => {
    const newSupplyList = new Array(length).fill({
      productType: '',
      price: '',
      supplyPrice: '',
      vatPrice: '',
      isCheck: false,
    });

    methods.setValue('supply', newSupplyList);
  };

  const modalRef = useRef<HTMLButtonElement | null>(null);
  const modalEl = modalRef.current;

  useEffect(() => {
    if (modalEl) {
      setTimeout(() => modalEl.focus(), 200);
    }
  }, [modalEl]);

  return (
    <>
      <Wrapper>
        <InputItem>
          <Item flex={1}>{index + 1}</Item>
          <Item flex={4}>
            <SelectBox name={`supply.${index}.productType`} index={index} />
          </Item>
          <Item flex={3} alignRight>
            <Input
              {...methods.register(`supply.${index}.supplyPrice`)}
              placeholder='자동입력'
              disabled
              alignRight
            />{' '}
            원
          </Item>
          <Item flex={3} alignRight>
            <Input
              {...methods.register(`supply.${index}.vatPrice`)}
              placeholder='자동입력'
              disabled
              alignRight
            />
            원
          </Item>
          <Item flex={5} hasOutline>
            <Input
              {...methods.register(`supply.${index}.price`, {
                onChange: (e) => onChangePrice({ e, index }),
              })}
              disabled={Boolean(!isSurgery) || isGroupRefund}
              placeholder='의료 금액 입력'
              autoComplete='off'
              hasOutline
            />
            원
          </Item>
          <SupplyInputBtn index={index} onReset={onReset} />
        </InputItem>
      </Wrapper>
      <Modal isOpen={isOpen600OverModal} hasBackdrop>
        <Modal.Header>
          <Title>
            <Icon.WarningFilled size={28} fillColor='var(--Secondary-Danger)' />
            {`시술 금액 ${DIRECT_PRICE / 10000}만원 초과`}완료
          </Title>
        </Modal.Header>
        <Message>
          {DIRECT_PRICE / 10000}만원 초과 시술금액에 대해서 합산 금액으로
          입력해주세요.
        </Message>
        <Modal.Footer>
          <ConfirmButton onClick={onOpenGroupRefundModal} ref={modalRef}>
            확인
          </ConfirmButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const Wrapper = styled.div``;

const InputItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.mono[10]};
`;

const Item = styled.div<{
  flex: number;
  hasOutline?: boolean;
  alignRight?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  gap: 10px;
  color: ${(props) => props.theme.mono[props.hasOutline ? 50 : 35]};
  flex: ${(props) => props.flex};
  line-height: ${(props) => props.theme.lineHeight.large};
  ${(props) =>
    props.alignRight &&
    css`
      justify-content: flex-start;
    `}
`;

const Input = styled.input<{ hasOutline?: boolean; alignRight?: boolean }>`
  padding: 11px 14px;
  border: ${(props) =>
    props.hasOutline ? `1px solid ${props.theme.grayColors.scale3}` : 'none'};
  border-radius: 4px;
  text-align: ${(props) => (props.hasOutline ? 'center' : 'right')};
  outline: none;
  :disabled {
    background-color: ${(props) =>
      props.hasOutline ? props.theme.mono[5] : '#ffffff'};
  }
  ::placeholder {
    text-align: ${(props) => (props.hasOutline ? 'center' : 'right')};
    color: ${(props) => props.theme.grayColors.scale3};
  }
  :focus {
    background-color: ${(props) => props.theme.blue[5]};
    border: 1px solid ${(props) => props.theme.blue[100]};
  }
  ${(props) =>
    props.alignRight &&
    css`
      width: 140px;
    `}
`;

const Message = styled.p`
  margin: 12px 0 0 0;
  font-size: 14px;
  line-height: 21px;
  color: var(--Mono-65);
  width: 400px;
`;

const ConfirmButton = styled.button`
  border-radius: 8px;
  background-color: #246cf6;
  color: #fff;
  width: 100%;
  height: 42px;
`;

const Title = styled.title`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin: 0;
  font-size: 21px;
  font-weight: 700;
`;

export default SupplyInputItem;

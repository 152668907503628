import VideoTableHeader from '@/components/kiosk/VideoTableHeader';
import BannerItem from '@/components/adSetting/BannerItem';
import styled from 'styled-components';
import { Ad } from '@/types/ad.type';

type Props = {
  data: Ad[];
};

const InActivateTable = ({ data }: Props) => {
  return (
    <Content>
      <Title>미노출 광고</Title>
      <GuideText>재생버튼 활성화시 배너광고가 노출됩니다.</GuideText>
      <Table>
        <VideoTableHeader />
        {data.length === 0 ? (
          <NoVideo>비활성화 된 배너 광고가 없습니다.</NoVideo>
        ) : (
          data.map((item, index) => (
            <BannerItem
              key={index}
              data={item}
              index={index}
              isActiveList={false}
            />
          ))
        )}
      </Table>
    </Content>
  );
};
export default InActivateTable;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.mono[80]};
`;

const Content = styled.section`
  padding: 0 40px;
  width: 100%;
`;

const NoVideo = styled.p`
  margin: 100px auto;
  text-align: center;
  color: #80848a;
  font-size: 14px;
`;

const GuideText = styled.p`
  font-size: 14px;
  color: #5f6165;
`;

const Table = styled.table`
  margin: 16px 0 40px;
  width: 100%;
`;

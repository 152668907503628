import { routes } from '@/routes';
import homeIcon from '@/assets/sideBar/home.png';
import homeIconActive from '@/assets/sideBar/home_active.png';
import refundIcon from '@/assets/sideBar/refund.png';
import refundActiveIcon from '@/assets/sideBar/refund_active.png';
import refundHistoryIcon from '@/assets/sideBar/refund_history.png';
import refundHistoryIconActive from '@/assets/sideBar/refund_history_active.png';
import taxDataIcon from '@/assets/sideBar/tax_data.png';
import taxDataIconActive from '@/assets/sideBar/tax_data_active.png';
import hospitalInfoIcon from '@/assets/sideBar/hospital_info.png';
import hospitalInfoIconActive from '@/assets/sideBar/hospital_info_active.png';
import kioskIcon from '@/assets/sideBar/kiosk_setting.png';
import kioskIconActive from '@/assets/sideBar/kiosk_setting_active.png';

export const SideNavigationInfo = [
  {
    id: 1,
    title: '홈',
    uri: { active: homeIconActive, notActive: homeIcon },
    link: [routes.home, routes.notice, routes.noticeDetail],
  },
  {
    id: 2,
    title: '환급하기',
    uri: { active: refundActiveIcon, notActive: refundIcon },
    link: [routes.refund],
  },
  {
    id: 3,
    title: '환급 내역',
    uri: { active: refundHistoryIconActive, notActive: refundHistoryIcon },
    link: [routes.refundDetail],
  },
  {
    id: 4,
    title: '세무 자료',
    uri: { active: taxDataIconActive, notActive: taxDataIcon },
    link: [routes.refundStatement, routes.refundDeparture],
  },
  {
    id: 5,
    title: '키오스크 설정',
    uri: { active: kioskIconActive, notActive: kioskIcon },
    link: [routes.kiosk],
  },
  {
    id: 6,
    title: '배너 광고 설정',
    uri: { active: kioskIconActive, notActive: kioskIcon },
    link: [routes.ad],
  },
  {
    id: 7,
    title: '병원 정보',
    uri: { active: hospitalInfoIconActive, notActive: hospitalInfoIcon },
    link: [routes.medicalInfo],
  },
];

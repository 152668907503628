import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import downArrowIcon from '@/assets/common/downArrow.png';
import { ProductTypeList } from '@/constants/supply';
import { useAppSelector } from '@/hooks/useReduxHooks';

interface IProps {
  name: string;
  selectValue: string;
  setSelectValue: Dispatch<SetStateAction<string>>;
}

interface IDropList {
  key: number;
  value: string;
}

interface IProductType {
  key: number;
  value: string;
  name: string;
}

function SelectBox({ name, selectValue, setSelectValue }: IProps) {
  const [isDrop, setIsDrop] = useState(false);
  const { productTypes, hospitalId } = useAppSelector<any>(
    (state) => state.user,
  );
  const { register, getValues, setValue } = useFormContext();
  const [typeList, setIsTypeList] = useState<IProductType[]>([]);

  const onMouseDown = (product: IDropList) => {
    setSelectValue(product.value);
    setIsDrop(false);
    setValue('productType', product.key);
  };

  useEffect(() => {
    let filterdList: IProductType[] = [];
    if (productTypes.length === 0) {
      setIsTypeList(ProductTypeList);
      return;
    }
    if (!String(getValues(name))) {
      setSelectValue('');
    }

    productTypes.map((productType: string) => {
      const result = ProductTypeList.find(
        (product) => String(product.key) === String(productType),
      );
      if (result) {
        filterdList = [...filterdList, result];
      }
    });

    if (
      hospitalId === 138 ||
      hospitalId === 1 ||
      hospitalId === 126 ||
      hospitalId === 156
    ) {
      filterdList = [
        ProductTypeList[14],
        ...filterdList.filter((item) => item.key !== 14),
      ];
    }

    // 엔젤미의원 시술 무작위
    if (hospitalId === 50 || hospitalId === 70) {
      const randomNum = Math.floor(Math.random() * filterdList.length);

      setSelectValue(filterdList[randomNum].value);
      setValue('productType', filterdList[randomNum].key);
    } else {
      setSelectValue(filterdList[0].value);
      setValue('productType', filterdList[0].key);
    }
    setIsTypeList(filterdList);
  }, []);

  return (
    <Container>
      <DropContainer
        onClick={(e) => {
          e.stopPropagation();
          setIsDrop((prev) => !prev);
        }}
        onBlur={() => setIsDrop(false)}
      >
        <Select {...register(name)} isDisplay />
        <Select
          value={selectValue}
          placeholder='시술명'
          readOnly
          isSelect={selectValue}
        />
        <ImageContainer>
          <Image src={downArrowIcon} />
        </ImageContainer>
      </DropContainer>
      {isDrop && (
        <DropList>
          {typeList.length !== 0 &&
            typeList.map((option) => (
              <DropItem
                key={option.key}
                onMouseDown={() => onMouseDown(option)}
              >
                <Text>{option.value}</Text>
              </DropItem>
            ))}
        </DropList>
      )}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
`;

const DropContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  position: relative;
`;

const Select = styled.input<{ isSelect?: string; isDisplay?: boolean }>`
  display: ${(props) => (props.isDisplay ? 'none' : 'flex')};
  align-items: center;
  justify-content: center;
  width: 220px;
  height: 100%;
  outline: none;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  cursor: pointer;
  color: #246cf6;
  background-color: ${(props) => (props.isSelect ? 'none' : 'transparent')};
  border: none;
`;

const Text = styled.span<{ isValue?: string }>`
  color: ${(props) =>
    props.isValue
      ? props.theme.priamryColors.primary
      : props.theme.grayColors.scale3};
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const DropList = styled.ul`
  position: absolute;
  top: 40px;
  padding: 20px 16px;
  width: 100%;
  max-height: 170px;
  border-radius: 4px;
  overflow-y: auto;
  flex-direction: column;
  z-index: 10;
  display: flex;
  border: 2px solid ${(props) => props.theme.grayColors.scale3};
  background-color: ${(props) => props.theme.pointColors.white};
`;

const DropItem = styled.li`
  cursor: pointer;
  padding: 5px 0;
  :hover {
    background-color: ${(props) => props.theme.priamryColors.primaryVarient};
    span {
      color: ${(props) => props.theme.priamryColors.primary};
    }
  }
`;

export default SelectBox;
